import React from 'react'
// import { Link } from 'react-router-dom';
import logo from '../assets/brand .png'
// import './styles.css'




function Nav() {
    return (
        <div>
            <nav id='navbar'
                className="h-16 w-screen bg-gray-400 flex justify-center items-center  shadow-2xg shadow-blue-400/50  font-bold"
            >
                <section className="h-20 w-11/12 flex justify-between items-center">


                    <div className="h-2/3 w-1/5   flex justify-center items-center">
                        <img src={logo} alt="Logo" />

                    </div>


                    

                    
                </section>
            </nav>


        </div>
    )
}

export default Nav
