import React from 'react'
import img from '../assets/one1.jpg'

function BodyPart() {

    return (

        <section className="h-screen w-screen  font-bold relative flex justify-center items-center bg-slate-300">
            <article className="h-4/5 w-4/12 mb-20  flex justify-center items-center">
                <form className="w-full h-full border-2 border-indigo-900 rounded-lg  flex justify-evenly flex-col items-center ">
                    <div className="relative">
                        <input type="text"
                            id="name"
                            className=" h-14 w-72 border-b-2 outline-none bg-transparent border-indigo-800  peer form-input w-full  focus:outline-none placeholder-transparent"
                            placeholder="Name" />
                        <label for="name"
                            className="text-neutral-500 text-sm font-bold uppercase absolute -top-4 left-2 -translate-y-1/2 transition-all peer-placeholder-shown:left-4 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-neutral-900 peer-focus:-top-4 peer-focus:left-2 peer-focus:text-neutral-600">
                            Name
                        </label>
                    </div>
                    <div class="relative">
                        <input type="number"
                            id="phnum"
                            className="h-14 w-72 border-b-2 outline-none bg-transparent border-indigo-800  peer form-input w-full  focus:outline-none placeholder-transparent"
                            placeholder="Phone Number" />
                        <label for="number"
                            className="text-neutral-500 text-sm font-bold uppercase absolute -top-4 left-2 -translate-y-1/2 transition-all peer-placeholder-shown:left-4 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-neutral-900 peer-focus:-top-4 peer-focus:left-2 peer-focus:text-neutral-600">
                            Phone Number
                        </label>
                    </div>
                    <div class="relative">
                        <input type="text"
                            id="address"
                            className="h-14 w-72 border-b-2 outline-none bg-transparent border-indigo-800  peer form-input w-full   focus:outline-none placeholder-transparent"
                            placeholder="Address" />
                        <label for="name"
                            class="text-neutral-500 text-sm font-bold uppercase absolute -top-4 left-2 -translate-y-1/2 transition-all peer-placeholder-shown:left-4 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-neutral-900 peer-focus:-top-4 peer-focus:left-2 peer-focus:text-neutral-600">
                            Enter Your Address
                        </label>
                    </div>

                    <button className="h-20 w-20 mr-72 border-2 border-slate-500 rounded-full transition ease-in-out delay-150 transparent hover:-translate-y-1 ml-72 hover:scale-110 hover:bg-red-300 duration-300">Click</button>
                </form>
                

            </article>
        </section>

    )
}

export default BodyPart
