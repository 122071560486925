import React from 'react'
import './index.css'
import Nav from './components/Nav'
import Body1 from './components/Body1'

function App() {
  return (
    <div>
      <Nav></Nav>
      <Body1></Body1>
    </div>
  )
}

export default App
